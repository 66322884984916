import {useEffect} from "react";
import {useField, useFormikContext} from "formik";
import {
    phonecodes,
    botswanaPaymentTypes,
    brazilPaymentTypes,
    cemacPaymentTypes,
    egyptPaymentTypes,
    // europePaymentTypes,
    ghanaPaymentTypes,
    guineaPaymentTypes,
    kenyaPaymentTypes,
    moroccoPaymentTypes,
    nigeriaPaymentTypes,
    other,
    southAfricaPaymentTypes,
    ugandaPaymentTypes,
    usPaymentTypes,
    waemuPaymentTypes,
    zambiaPaymentTypes,
    mobileProvidersBeninXOF,
    mobileProvidersBFXOF, mobileProvidersIvoryCoastXOF, mobileProvidersSenegalXOF, mobileProvidersTogoXOF,
    ghanaBusinessPaymentTypes,
    // europeBusinessPaymentTypes,
    waemuBusinessPaymentTypes,
    southAfricaBusinessPaymentTypes,
    cemacBusinessPaymentTypes,
    usBusinessPaymentTypes,
    kenyaBusinessPaymentTypes,
    egyptBusinessPaymentTypes,
    nigeriaBusinessPaymentTypes
} from "../../../constants/StaticData";
import {
    validationSchema, validationSchemaACH, validationSchemaAza, validationSchemaBACS, validationSchemaBill,
    validationSchemaBWPBank,
    validationSchemaCash, validationSchemaEFT, validationSchemaEGPBank,
    validationSchemaEUR,
    validationSchemaGHSBank, validationSchemaInterac, validationSchemaKESBank, validationSchemaKESMobile,
    validationSchemaMADCash, validationSchemaMobile,
    validationSchemaNGNBank,
    validationSchemaNGNMobile,
    validationSchemaNGNUSDBank, validationSchemaPix, validationSchemaTed,
    validationSchemaUGXBank,
    validationSchemaUGXMobile,
    validationSchemaUSDBank, validationSchemaXBank,
    validationSchemaXOFCash, validationSchemaXOFMobile, validationSchemaZARBank, validationSchemaZMWBank
} from "./ValidationSchemas";
import {useAppSelector} from "../../../hooks/redux";
import * as yup from "yup";

export const CurrencyHelper = (props: any) => {

    const {
        accountsList,
        payerAccount,
        setCurrency
    } = props;

    useEffect(() => {
        accountsList.forEach((account: any) => {
            if (account.number === payerAccount) {
                setCurrency(account.currency)
            }
        })
    }, [accountsList, payerAccount, setCurrency])

    return null
}

export const PaymentTypeHelper = (props: any) => {

    const { values } = useFormikContext();
    const [, , helpers] = useField<string>(props.name);
    const { setValue } = helpers;

    useEffect(() => {
        setValue("")
        // @ts-ignore
    },[values.recipientsBankCountry, setValue])

    return null
}

export const PayerAccountHelper = (props: any) => {

    const paymentRegion = useAppSelector(state => state.transactionsReducer.filters.paymentRegion)

    const [, , helpers] = useField<string>(props.name);
    const { setValue } = helpers;

    useEffect(() => {
        setValue("")
    },[paymentRegion, setValue])

    return null
}

export const getItemsPaymentType = (recipientsBankCountry: string) => {
    if (recipientsBankCountry === "Nigeria") {
        return nigeriaPaymentTypes
    }

    if (recipientsBankCountry === "Ghana") {
        return ghanaPaymentTypes
    }

    if (recipientsBankCountry === "Uganda") {
        return ugandaPaymentTypes
    }

    // if (recipientsBankCountry === "Europe/SEPA") {
    //     return europePaymentTypes
    // }

    if (recipientsBankCountry === "United States") {
        return usPaymentTypes
    }

    if (recipientsBankCountry === "Morocco") {
        return moroccoPaymentTypes
    }

    if (recipientsBankCountry === "WAEMU Region/XOF") {
        return waemuPaymentTypes
    }

    if (recipientsBankCountry === "CEMAC Region/XAF") {
        return cemacPaymentTypes
    }

    if (recipientsBankCountry === "Guinea") {
        return guineaPaymentTypes
    }

    if (recipientsBankCountry === "South Africa") {
        return southAfricaPaymentTypes
    }

    if (recipientsBankCountry === "Botswana") {
        return botswanaPaymentTypes
    }

    if (recipientsBankCountry === "Brazil") {
        return brazilPaymentTypes
    }

    if (recipientsBankCountry === "Zambia") {
        return zambiaPaymentTypes
    }

    if (recipientsBankCountry === "Kenya") {
        return kenyaPaymentTypes
    }

    if (recipientsBankCountry === "Egypt") {
        return egyptPaymentTypes
    }

    return other

}

export const getPhoneCode = (recipientsBankCountry: string, country: string | null) => {
    let targetCountry = country || recipientsBankCountry;
    targetCountry = targetCountry === "XOF" ? "Senegal" : targetCountry;
    const matchingCountry = phonecodes.find((entry) => entry.na === targetCountry);

    return matchingCountry ? matchingCountry : phonecodes[0];
};

export const getMobileProvider = (recipientCountry: string) => {
    if (recipientCountry === "Benin") {
        return mobileProvidersBeninXOF
    }

    if (recipientCountry === "Burkina Faso") {
        return mobileProvidersBFXOF
    }

    if (recipientCountry === "Ivory Coast") {
        return mobileProvidersIvoryCoastXOF
    }

    if (recipientCountry === "Senegal") {
        return mobileProvidersSenegalXOF
    }

    if (recipientCountry === "Togo") {
        return mobileProvidersTogoXOF
    }

    return other

}

// Function that returns the validation schema based on the payment region
export const getValidationSchema = (paymentTypeByRegion: string, paymentRegion: string) => {

    if (paymentRegion && paymentRegion !== "PAN AFRICA") {
        switch (paymentRegion) {
            case "ACH (only for USD)":
                return validationSchemaACH;
            case "BACS (only for GBP)":
                return validationSchemaBACS;
            case "INTERAC (only for CAD)":
                return validationSchemaInterac;
            case "EFT (only for CAD)":
                return validationSchemaEFT;
            case "Bill Payment (only for CAD)":
                return validationSchemaBill;
            default:
                return validationSchema;
        }
    }

    if (paymentRegion === "PAN AFRICA") {
    switch (paymentTypeByRegion) {
        case "NGN::Bank":
            return validationSchemaNGNBank;

        case "NGN::Mobile":
        case "NigeriaUSD::Cash":
            return validationSchemaNGNMobile;

        case "NigeriaUSD::Bank":
            return validationSchemaNGNUSDBank;

        case "GHS::Bank":
            return validationSchemaGHSBank;

        case "GHS::Mobile":
        case "XAF::Mobile":
        case "GNF::Mobile":
            return validationSchemaMobile;

        case "GHS::Cash":
            return validationSchemaCash;

        case "UGX::Bank":
            return validationSchemaUGXBank;

        case "UGX::Mobile":
            return validationSchemaUGXMobile;

        case "EUR::Bank":
        case "GBP::Bank":
            return validationSchemaEUR;

        case "USD::Bank":
            return validationSchemaUSDBank;

        case "MAD::Cash":
            return validationSchemaMADCash;

        case "XOF::Cash":
            return validationSchemaXOFCash;

        case "XOF::Mobile":
            return validationSchemaXOFMobile;

        case "XOF::Bank":
        case "XAF::Bank":
            return validationSchemaXBank;

        case "ZAR::Bank":
            return validationSchemaZARBank;

        case "BWP::Bank":
            return validationSchemaBWPBank;

        case "PIX Payments":
            return validationSchemaPix;

        case "TED Payments":
            return validationSchemaTed;

        case "ZMW::Bank":
            return validationSchemaZMWBank;

        case "KES::Bank":
            return validationSchemaKESBank;

        case "KES::Mobile":
            return validationSchemaKESMobile;

        case "EGP::Bank":
            return validationSchemaEGPBank;

        default:
            return validationSchemaAza;
    }
    }

    return validationSchema;

}

export const getBusinessPaymentType = (recipientsBankCountry: string) => {

    if (recipientsBankCountry === "Ghana") {
        return ghanaBusinessPaymentTypes
    }

    // if (recipientsBankCountry === "Europe/SEPA") {
    //     return europeBusinessPaymentTypes
    // }

    if (recipientsBankCountry === "WAEMU Region/XOF") {
        return waemuBusinessPaymentTypes
    }

    if (recipientsBankCountry === "South Africa") {
        return southAfricaBusinessPaymentTypes
    }

    if (recipientsBankCountry === "CEMAC Region/XAF") {
        return cemacBusinessPaymentTypes
    }

    if (recipientsBankCountry === "United States") {
        return usBusinessPaymentTypes
    }

    if (recipientsBankCountry === "Kenya") {
        return kenyaBusinessPaymentTypes
    }

    if (recipientsBankCountry === "Egypt") {
        return egyptBusinessPaymentTypes
    }

    if (recipientsBankCountry === "Nigeria") {
        return nigeriaBusinessPaymentTypes
    }

    return other

}


export const ValidationSchemaWithIgnore = (
    baseSchema: yup.ObjectSchema<any>,
    ignoreFields: string[] = []
): yup.ObjectSchema<any> => {
    let schema = baseSchema;

    ignoreFields.forEach(field => {
        schema = schema.shape({
            [field]: yup.mixed().notRequired(),
        });
    });

    return schema;
};
